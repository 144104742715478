import { useState, useEffect, useRef } from "react";
import { Outlet } from "react-router-dom";
import NavMenuSport from "../components/NavMenu/NavMenuSport";
import NavBarSport from "../components/NavBar/NavBarSport";
import FooterSport from "../components/Footer/FooterSport";
import BasketSport from "../components/Basket/BasketSport";
import FavoriteSport from "../components/Favorite/FavoriteSport";
import StreamsSport from "../components/Streams/StreamsSport";
import MobileSearch from "../components/MobileComponents/MobileSearch";
import MobileMenu from "../components/MobileComponents/MobileMenu";
import RedirectNotification from "../components/RedirectNotification/RedirectNotification";
import useSuccessMessage from "../hook/useSuccessMessage";
import Dialog from "../components/Dialog/Dialog";
import { FavoriteContext } from "../contexts/FavoriteContext";
import { BasketContext } from "../contexts/BasketContext";
import { useContext } from "react";
import classNames from "classnames";

function SportContainer(params) {
    const [menuState, setMenuState] = useState({
        basket: false,
        favorite: false,
        streams: false,
        mobileSearch: false,
        mobileMenu: false,
        redirectNotification: false,
    });
    const [redirectUrl, setRedirectUrl] = useState("");

    const [navMenuHeight, setNavMenuHeight] = useState(0);
    const navMenuRef = useRef(null);
    const successMessage = useSuccessMessage({ type: "favorites" });
    const { refreshFavoriteItems } = useContext(FavoriteContext);
    const { refreshBasketItems, addItem } = useContext(BasketContext);
    const mainClass = classNames(
        "relative flex-1",
        process.env.REACT_APP_NODE_ENV === "stage" ? "bg-pink-300" : ""
    );

    const toggleMenu = (menuItemName) => {
        const isOpeningBasket = () => {
            return menuItemName === "basket" && !menuState.basket;
        };
        const isClosingBasket = () => {
            return menuItemName === "basket" && menuState.basket;
        };
        const isClosingFavorite = () => {
            return menuItemName === "favorite" && menuState.favorite;
        };
        const isOpeningFavorite = () => {
            return menuItemName === "favorite" && !menuState.favorite;
        };

        setMenuState((prev) => {
            return Object.keys(prev).reduce((acc, key) => {
                acc[key] = key === menuItemName ? !prev[key] : false;
                return acc;
            }, {});
        });

        if (isClosingBasket()) {
            refreshFavoriteItems();
        }
        if (isOpeningBasket()) {
            refreshBasketItems();
        }
        if (isClosingFavorite() || isOpeningFavorite()) {
            refreshFavoriteItems();
        }
    };

    useEffect(() => {
        eyeZon("on", "PUT_IN_CART_FROM_WIDGET", function (data) {
            if (data.sku) {
                const item = { ean: data.sku };
                addItem(item, 1);
            } else if (data.target) {
                const item = { ean: data.target };
                addItem(item, 1);
            }
        });

        eyeZon("on", "STREAM_RECORD_PUT_IN_CART_FROM_WIDGET", function (data) {
            if (data.sku) {
                const item = { ean: data.sku };
                addItem(item, 1);
            } else if (data.target) {
                const item = { ean: data.target };
                addItem(item, 1);
            }
        });

        eyeZon("on", "PUT_IN_CART_REQUEST_ACCEPTED", function (data) {
            if (data.sku) {
                const item = { ean: data.sku };
                addItem(item, 1);
            } else if (data.target) {
                const item = { ean: data.target };
                addItem(item, 1);
            }
        });

        // HotJat
        (function (h, o, t, j, a, r) {
            h.hj =
                h.hj ||
                function () {
                    (h.hj.q = h.hj.q || []).push(arguments);
                };
            h._hjSettings = { hjid: 5197926, hjsv: 6 };
            a = o.getElementsByTagName("head")[0];
            r = o.createElement("script");
            r.async = 1;
            r.src = t + h._hjSettings.hjid + j + h._hjSettings.hjsv;
            a.appendChild(r);
        })(window, document, "https://static.hotjar.com/c/hotjar-", ".js?sv=");

        //Facebook
        !(function (f, b, e, v, n, t, s) {
            if (f.fbq) return;
            n = f.fbq = function () {
                n.callMethod
                    ? n.callMethod.apply(n, arguments)
                    : n.queue.push(arguments);
            };
            if (!f._fbq) f._fbq = n;
            n.push = n;
            n.loaded = !0;
            n.version = "2.0";
            n.queue = [];
            t = b.createElement(e);
            t.async = !0;
            t.src = v;
            s = b.getElementsByTagName(e)[0];
            s.parentNode.insertBefore(t, s);
        })(
            window,
            document,
            "script",
            "https://connect.facebook.net/en_US/fbevents.js"
        );
        fbq("init", "1232448944692382");
        fbq("track", "PageView");

        //Yandex
        (function (m, e, t, r, i, k, a) {
            m[i] =
                m[i] ||
                function () {
                    (m[i].a = m[i].a || []).push(arguments);
                };
            m[i].l = 1 * new Date();
            for (var j = 0; j < document.scripts.length; j++) {
                if (document.scripts[j].src === r) {
                    return;
                }
            }
            (k = e.createElement(t)),
                (a = e.getElementsByTagName(t)[0]),
                (k.async = 1),
                (k.src = r),
                a.parentNode.insertBefore(k, a);
        })(
            window,
            document,
            "script",
            "https://mc.yandex.ru/metrika/tag.js",
            "ym"
        );

        ym(98895916, "init", {
            clickmap: true,
            trackLinks: true,
            accurateTrackBounce: true,
            webvisor: true,
            ecommerce: "dataLayer",
        });
    }, []);

    return (
        <>
            <div className="flex-1" ref={navMenuRef}>
                <NavMenuSport
                    actions={{
                        basket: () => toggleMenu("basket"),
                        favorite: () => toggleMenu("favorite"),
                        streams: () => toggleMenu("streams"),
                        mobileSearch: () => toggleMenu("mobileSearch"),
                        mobileMenu: () => toggleMenu("mobileMenu"),
                    }}
                    props={{ isMobileMenuOpen: menuState.mobileMenu }}
                />
            </div>
            <NavBarSport />
            <main className={mainClass}>
                <Dialog
                closeDialog={() => toggleMenu("basket")}
                isOpen={menuState.basket}
                className="h-3/4"
                >
                    <BasketSport
                        closeBasket={() => toggleMenu("basket")}
                        openRedirectNotification={() =>
                            toggleMenu("redirectNotification")
                        }
                        setRedirectUrl={setRedirectUrl}
                    />
                </Dialog>
                <Dialog
                className="h-3/4"
                closeDialog={() => toggleMenu("favorite")}
                isOpen={menuState.favorite}
                >
                    <FavoriteSport
                        closeFavorite={() => toggleMenu("favorite")}
                    />
                </Dialog>
                {menuState.streams && (
                    <div
                        className="w-full h-full absolute flex items-start justify-center bg-black bg-opacity-50 z-10"
                        onClick={(e) => {
                            if (e.target === e.currentTarget) {
                                toggleMenu("streams");
                            }
                        }}
                    >
                        <StreamsSport
                            closeStreams={() => toggleMenu("streams")}
                        />
                    </div>
                )}
                {menuState.mobileSearch && (
                    <div className="w-full h-full absolute flex items-start justify-end bg-white z-10">
                        <MobileSearch
                            onSuggestionSubmitCallBack={() =>
                                toggleMenu("mobileSearch")
                            }
                        />
                    </div>
                )}
                {menuState.mobileMenu && (
                    <div className="w-full h-full absolute flex items-start justify-end bg-white z-10">
                        <MobileMenu
                            closeMenu={() => toggleMenu("mobileMenu")}
                        />
                    </div>
                )}
                {menuState.redirectNotification && (
                    <div className="w-full h-full absolute flex justify-center bg-black bg-opacity-50 z-10">
                        <RedirectNotification redirectUrl={redirectUrl} />
                    </div>
                )}
                <Outlet />
            </main>
            <FooterSport />
            <img
                height="1"
                width="1"
                style={{ display: "none" }}
                src="https://www.facebook.com/tr?id=1232448944692382&ev=PageView&noscript=1"
            />
            <div>
                <img
                    src="https://mc.yandex.ru/watch/98895916"
                    style={{ position: "absolute", left: "-9999px" }}
                    alt=""
                />
            </div>
        </>
    );
}

export default SportContainer;
