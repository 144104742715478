import { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { submitEmail } from "../../functions/fetch";
import { isEmail, isEmpty } from "validator";
import ProgressBar from "../ProgressBar/ProgressBar";
import {
    StreamsLogoSVG,
    EyeSVG,
    CursorSVG,
    Eye5SVG,
} from "../../images/SVGAssets";
import Lidabro from "../Lidabro/Lidabro";
import ArrowButton from "./components/ArrowButton";
import { XClose2SVG } from "../../images/SVGAssets";

function StreamsSport({closeStreams}) {
    const defaultFormData = {
        email: "",
        ip: "",
    };

    const validationStatusDefault = {
        email: false,
    };

    const [isAnimating, setIsAnimating] = useState(false);
    const [currentContent, setCurrentContent] = useState(0);
    const { t } = useTranslation();
    const [formData, setFormData] = useState(defaultFormData);
    const [formState, setFormState] = useState("default");
    const [validationStatus, setValidationStatus] = useState(
        validationStatusDefault
    );

    const handleChange = (e) => {
        const { name, value } = e.target;
        setValidationStatus(validationStatusDefault);
        setFormData((prevState) => ({
            ...prevState,
            [name]: value,
        }));
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        if (isEmpty(formData.email)) {
            return;
        }

        if (!isEmail(formData.email)) {
            console.log("Not email");
            setValidationStatus((prevState) => ({
                ...prevState,
                email: true,
            }));
        }

        if (isEmail(formData.email)) {
            let successful = submitEmail(formData);
            if (successful == "true") {
                setFormData(defaultFormData);
                setFormState("submitted");
            } else {
                setFormState("error");
            }
        }
    };

    function getLogoContent(id) {
        switch (id) {
            case 0:
                return <StreamsLogoSVG />;
            case 1:
                return (
                    <div className="relative flex items-center justify-center gap-[12.5px] px-[27.5px] py-4 border-[2.5px] rounded-lg border-white border-opacity-30 shadow-[0px_1px_2px_0px_rgba(16,24,40,0.05)] text-white bg-[linear-gradient(278deg,_#00FFA3_0%,_#0128FF_102.13%)]">
                        <div className="fill-white">
                            <EyeSVG />
                        </div>
                        <p className="font-secondary text-lg font-semibold">
                            {t("See live")}
                        </p>
                        <div className="absolute top-[50px] right-[-20px]">
                            <CursorSVG />
                        </div>
                    </div>
                );
            case 2:
                return (
                    <div className="relative flex flex-col items-center justify-center text-white">
                        <div>
                            <Eye5SVG />
                        </div>
                        <p className="font-secondary text-3xl leading-[46px] font-medium">
                            {t("Streams")}
                        </p>
                        <div className="absolute top-[-20px] right-[5px] flex items-center justify-center w-10 h-10 rounded-full bg-white">
                            <p className="font-secondary text-xl leading-[74px] font-bold text-black">
                                1
                            </p>
                        </div>
                    </div>
                );
            case 3:
                return (
                    <div>
                        <Lidabro/>
                    </div>
                );
            default:
                break;
        }
    }

    function getContent(id) {
        switch (id) {
            case 0:
                return (
                    <>
                        <p className="font-main text-2xl font-medium text-center text-gray-lidabro-breadcrumb">
                            {t("Welcome to Lidabro!")}
                        </p>
                        <p className="font-secondary text-base font-medium text-gray-lidabro-breadcrumb text-center">
                            {t(
                                "Our platform offers live product demonstrations with sellers and product experts."
                            )}
                        </p>
                    </>
                );
            case 1:
                return (
                    <>
                        <p className="font-main text-2xl font-medium text-center text-gray-lidabro-breadcrumb">
                            {t("Jump into Stream")}
                        </p>
                        <p className="font-secondary text-base font-medium text-gray-lidabro-breadcrumb text-center">
                            {t("To get started, simply press")}{" "}
                            <span className="font-bold">"{t("See Live")}"</span>{" "}
                            or{" "}
                            <span className="font-bold">
                                "{t("Join Stream")}"
                            </span>{" "}
                            {t(
                                "below any product or category that interests you."
                            )}
                        </p>
                    </>
                );
            case 2:
                return (
                    <>
                        <p className="font-main text-2xl font-medium text-center text-gray-lidabro-breadcrumb">
                            {t("Access Streams")}
                        </p>
                        <p className="font-secondary text-base font-medium text-gray-lidabro-breadcrumb text-center">
                            {t(
                                "Here, you can view your active streams, recordings, and chats with sellers."
                            )}
                        </p>
                    </>
                );
            case 3:
                return (
                    <>
                        <p className="font-main text-2xl font-medium text-center text-gray-lidabro-breadcrumb">
                            {t("Subscribe and get Discount!")}
                        </p>
                        <p className="font-secondary text-base font-medium text-gray-lidabro-breadcrumb text-center">
                            {t(
                                "Get notified of our official launch date and be the first to receive exclusive offers!"
                            )}
                        </p>
                        <p className="font-secondary text-base font-medium text-gray-lidabro-breadcrumb text-center">
                            {t("We promise not to spam:)")}
                        </p>
                        <form
                            className="flex flex-col gap-2"
                            onSubmit={handleSubmit}
                        >
                            <input
                                className="w-full border p-2 rounded-lg placeholder:text-center"
                                type="text"
                                placeholder={t("Enter your email")}
                                name="email"
                                value={formData.email}
                                onChange={handleChange}
                            />
                            <button
                                type="submit"
                                className="w-full lg:w-fit px-5 py-3 bg-blue-lidabro-landing-default rounded-3xl font-main text-sm font-medium text-white text-nowrap"
                            >
                                {t("Subscribe")}
                            </button>
                        </form>
                        {validationStatus["email"] && (
                            <p className="px-3 py-1 text-sm text-blue-lidabro-landing-default">
                                Email is invalid
                            </p>
                        )}
                    </>
                );
            default:
                break;
        }
    }

    useEffect(() => {
        const timer = setTimeout(() => {
            setIsAnimating(true);
            setTimeout(() => {
                setCurrentContent((prevContent) =>
                    prevContent < 3 ? prevContent + 1 : prevContent
                );
                setIsAnimating(false);
            }, 1000);
        }, 4000);

        return () => clearTimeout(timer);
    }, [currentContent]);

    return (
        <div className="flex h-full w-full absolute items-start justify-center">
            <ArrowButton
                onClick={() => {
                    setCurrentContent((prev) => {
                        if(prev === 0) {
                            return prev
                        }

                        return --prev;
                    })
                }}
            />
            <div className="w-3/4 sm:w-1/2 h-[640px] flex flex-col max-w-[450px] border-[5px] rounded-[20px] border-white shadow-[0px_0px_8px_0px_rgba(0,0,0,0.08),_0px_0px_16px_0px_rgba(0,0,0,0.24)] bg-white">
                <div className="h-1/2 flex items-center justify-center bg-[linear-gradient(278deg,_rgba(0,255,163,0.75)_0%,_rgba(1,40,255,0.75)_102.13%)] rounded-t-[15px] relative">
                    <button
                        className="flex py-2 px-3 items-center justify-center cursor-pointer text-white absolute right-0 top-0"   
                        onClick={closeStreams} 
                    >
                        <XClose2SVG 
                            color="white"
                        />
                    </button>
                    <div className={`${isAnimating ? "" : ""}`}>
                        {getLogoContent(3)}
                    </div>
                </div>

                <div className="flex flex-grow flex-col justify-between px-6 pt-12 pb-6 rounded-b-[15px]">
                    <div
                        className={`flex flex-col gap-6 transition-transform duration-1000 ${
                            isAnimating ? "" : ""
                        }`}
                    >
                        {getContent(currentContent)}
                    </div>

                    <div className="grid grid-cols-4 gap-2 mt-4">
                        <ProgressBar
                            isAnimating={currentContent === 0}
                            finished={currentContent > 0}
                        />
                        <ProgressBar
                            isAnimating={currentContent === 1}
                            finished={currentContent > 1}
                        />
                        <ProgressBar
                            isAnimating={currentContent === 2}
                            finished={currentContent > 2}
                        />
                        <ProgressBar
                            isAnimating={currentContent === 3}
                            finished={false}
                        />
                    </div>
                </div>
            </div>
            <ArrowButton
                isLeft={false}
                onClick={() => {
                    setCurrentContent((prev) => {
                        if(prev === 3) {
                            return prev
                        }

                        return ++prev;
                    })
                }}
            />
        </div>
    );
}

export default StreamsSport;
