function Lidabro({width="133", height="133"}) {

  const faceWidth = width / 1.546; 
  const faceHeight = height / 1.583; 

  return (
    <div className="relative flex justify-center items-center">
      <Square
        width={width}
        height={height}
        className="absolute"
      />
      <Face
        width={faceWidth}
        height={faceHeight}
        className="absolute"
      />
    </div>
  )
}

function Square({width = "133", height="133", className=""}) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      fill="none"
      viewBox="0 0 133 133"
      className={className}
    >
      <rect
      width="108.594"
      height="108.594"
      x="28.106"
      fill="#fff"
      rx="15.892"
      transform="rotate(15 28.106 0)"
      ></rect>
    </svg>
  )
}

const Face = ({width = "86", height="84", className = ''}) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    height={height}
    fill="none"
    viewBox="0 0 86 84"
    className={className}
  >
    <circle cx="80.053" cy="29.047" r="5.541" fill="#475467"></circle>
    <circle
      cx="42.398"
      cy="42.398"
      r="41.56"
      fill="url(#paint0_linear_7317_11089)"
    ></circle>
    <circle cx="55.495" cy="23.38" r="5.541" fill="#475467"></circle>
    <path
      stroke="#475467"
      strokeLinecap="round"
      strokeWidth="3.778"
      d="M82.572 57.636C74.26 68.718 51.918 65.96 38.619 47.12"
    ></path>
    <defs>
      <linearGradient
        id="paint0_linear_7317_11089"
        x1="17.212"
        x2="68.049"
        y1="-1.496"
        y2="86.021"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#0236FA"></stop>
        <stop offset="1" stopColor="#01F7A8"></stop>
      </linearGradient>
    </defs>
  </svg>
);

export default Lidabro;