import { useTranslation } from "react-i18next";
import { fetchItemsBanner } from "../../functions/fetch";
import { Eye4SVG } from "../../images/SVGAssets";
import Cookies from "js-cookie";

function BannerItemsSport(params) {
    const campaign = fetchItemsBanner(params.category);
    const { i18n, t } = useTranslation();

    let banner;
    if (campaign.length > 0) {
        banner = campaign[0];
    }

    return (
        <>
            {banner && (
                <div
                    className="flex flex-col lg:flex-row items-center justify-center lg:justify-between min-h-[250px] gap-3 bg-cover lg:px-[45px] lg:py-9"
                    style={{
                        backgroundImage: `url(${banner.image})`,
                    }}
                >
                    <div className="flex flex-col gap-3">
                        <p className="w-2/3 lg:w-full text-center font-secondary lg:font-main lg:text-3xl lg:font-normal lg:leading-[38px] text-lg not-italic font-semibold leading-7 text-white">
                            {t(banner.title)}
                        </p>
                        <p className="w-2/3 lg:w-full text-center font-secondary text-xs lg:text-base not-italic font-medium leading-[18px] lg:leading-6 text-white">
                            {t(banner.description)}
                        </p>
                    </div>
                    <button
                        data-eyezon={i18n.language === "en" ? "232" : "235"}
                        className="flex px-[18px] py-3 mt-3 items-center justify-center gap-[6px] border border-gray-lidabro-border-2 bg-white rounded-lg text-nowrap"
                        style={{
                            boxShadow: "0px 1px 2px 0px rgba(16, 24, 40, 0.05)",
                        }}
                        onClick={() => {
                            if (!Cookies.get("streamOnClicked")) {
                                ym(98895916, "reachGoal", "StreamOn", {
                                    session: `${Cookies.get("sessionId")}`,
                                });
                                Cookies.set("streamOnClicked", "true", {
                                    expires: 365,
                                });
                            }
                        }}
                    >
                        <div>
                            <Eye4SVG />
                        </div>
                        <p>{t(banner.buttonText)}</p>
                    </button>
                </div>
            )}
        </>
    );
}

export default BannerItemsSport;
