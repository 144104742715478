import ButtonIcon from "../../Controls/ButtonIcon";
import { TrashSVG } from "../../../images/SVGAssets";
import { ShareSVG } from "../../../images/SVGAssets";
import { XClose2SVG } from "../../../images/SVGAssets";
import { useTranslation } from "react-i18next";

function ControlsTopSection({isBasketEmpty, closeBasket, openClearBasketSection}) {
    const { t } = useTranslation();
    return (
        <div className="max-w-full hidden sm:flex items-center justify-between pt-4 pb-6 px-[45px] bg-white">
            <p className="font-main text-2xl font-medium text-gray-lidabro-text">
                {t("Shopping Basket")}
            </p>
            <div className="flex items-center">
                {!isBasketEmpty && (
                <>
                    <ButtonIcon
                        icon={<TrashSVG />}
                        callback={openClearBasketSection}
                    />
                    <ButtonIcon icon={<ShareSVG />} />
                </>
                )}
                <ButtonIcon
                    icon={<XClose2SVG />}
                    callback={closeBasket}
                />
            </div>
        </div>
    )
}

export default ControlsTopSection