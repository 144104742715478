import { useRef, useEffect } from "react";
function Dialog({children, isOpen, closeDialog, className}) {
    
    const dialogRef= useRef(null);

    useEffect(() => {
        const onClickOutsideOfDialog = (e) => {
            if(!isOpen || !dialogRef.current) {
                return;
            }

            if (e.target === dialogRef.current) {
                dialogRef.current.close()
                closeDialog()
            }
        }

        
        if(!dialogRef.current) {
            return
        }
        
        if(isOpen) {
            document.body.style.overflow = "hidden";
            dialogRef.current.showModal()
        }
        else {
            dialogRef.current.close()
            document.body.style.overflow = "auto";
        }

        window.addEventListener('click', onClickOutsideOfDialog)

        return () => {
            window.removeEventListener("click", onClickOutsideOfDialog);
            document.body.style.overflow = "auto";
        }
    }, [isOpen])

    return (
        <dialog className={`rounded-lg ${className} overflow-x-hidden`} ref={dialogRef}>
            {children}
        </dialog>
    )
}

export default Dialog;