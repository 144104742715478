import { useState } from "react";
import { useTranslation } from "react-i18next";
import Slider from "../Slider/Slider";
import BadgeSmall from "../Badge/BadgeSmall";
import { ArrowRight4SVG, EyeSVG } from "../../images/SVGAssets";
import Cookies from "js-cookie";

function BannerSport(params) {
    const [currentIndex, setCurrentIndex] = useState(0);
    const { i18n, t } = useTranslation();

    const goToSlide = (index) => {
        setCurrentIndex(index);
    };

    const banners = [
        {
            id: 1,
            url: "/images/banners/banner1.png",
            altText: "Banner 1",
        },
    ];

    return (
        <div className="relative w-full flex flex-col justify-center items-end self-stretch gap-[5px] p-3">
            <div
                className="flex items-center justify-center grow w-full min-h-[320px] bg-lightgray bg-cover bg-no-repeat bg-center"
                style={{
                    backgroundImage: `linear-gradient(266deg, rgba(0, 0, 0, 0.00) 1.07%, rgba(0, 0, 0, 0.70) 100%), url(${
                        banners.length > 0
                            ? banners[currentIndex].url
                            : "https://via.placeholder.com/1000x1000?text=No+image"
                    })`,
                }}
            >
                <div className="flex flex-col items-center justify-center gap-3 sm:gap-8 py-[50px] px-[35px]">
                    <div className="flex items-center gap-2 ps-1 pe-[10px] py-1 rounded-full border-[0.5px] border-gray-lidabro-border-3 bg-white bg-opacity-30">
                        <BadgeSmall
                            bgColor={"bg-white"}
                            borderColor={"border-gray-lidabro-border-3"}
                            textColor={"text-gray-lidabro-text"}
                            label={t("up to 10% off")}
                        />
                        <p className="font-secondary text-xs leading-[18px] font-medium text-white">
                            {t("Enjoy special price offer!")}
                        </p>
                    </div>
                    <p className="font-secondary sm:font-main text-base sm:text-3xl lg:text-4xl sm:leading-[38px] lg:leading-[44px] font-medium sm:font-normal text-white text-center">
                        {t("New arrival of fitness equipment")}
                    </p>
                    <p className="font-secondary text-xs sm:text-lg leading-[18px] sm:leading-7 font-normal sm:font-medium text-white text-center">
                        {t(
                            "Join live stream with the shop and let the streamer guide your through the product range!"
                        )}
                    </p>
                    <button
                        data-eyezon={i18n.language === "en" ? "232" : "235"}
                        data-eyezon-title={"Fitness Equipment"}
                        data-eyezon-target={"Fitness Equipment"}
                        data-eyezon-put-in-cart
                        className={
                            "flex items-center justify-center px-[18px] py-3 gap-[6px] rounded-lg border border-gray-lidabro-border-2 bg-white text-black font-secondary text-base font-semibold"
                        }
                        onClick={() => {
                            if (!Cookies.get("streamOnClicked")) {
                                ym(98895916, "reachGoal", "StreamOn", {
                                    session: `${Cookies.get("sessionId")}`,
                                });
                                Cookies.set("streamOnClicked", "true", {
                                    expires: 365,
                                });
                            }
                        }}
                    >
                        <EyeSVG fill="black" />
                        {t("See live")}
                    </button>
                </div>
            </div>
            <div className="flex gap-3 absolute left-[22px] top-[22px]">
                {banners.length > 1
                    ? banners.map((image, index) => (
                          <span
                              key={index}
                              className={`inline-block w-2 h-2 rounded-full ${
                                  index === currentIndex
                                      ? "bg-blue-lidabro-button"
                                      : "bg-gray-lidabro-border-3"
                              }`}
                              onClick={() => goToSlide(index)}
                          />
                      ))
                    : null}
            </div>
        </div>
    );
}

export default BannerSport;
