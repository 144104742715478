import { useEffect, useState, useRef } from "react";
import { useParams } from "react-router-dom";
import Breadcrumbs from "../../components/Breadcrumbs/Breadcrumbs";
import BreadcrumbsSkeleton from "../../components/Breadcrumbs/BreadcrumbsSkeleton";
import ItemInfoSport from "../../components/ItemInfo/ItemInfoSport";
import SportItemsCarousel from "../../components/ItemsCarousel/SportItemsCarousel";
import FloatingIsland from "../../components/FloatingIsland/FloatingIsland";
import {
    fetchSportRelatedItems,
    fetchItemByArticle,
} from "../../functions/fetch";

function ItemSport(params) {
    const { item } = useParams();
    const [data, setData] = useState(null);
    const [relatedItems, setRelatedItems] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [navMenuHeight, setNavMenuHeight] = useState(0);
    const navMenuRef = useRef(null);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const fetchedItem = await fetchItemByArticle(item);
                const fetchedRelatedItems = await fetchSportRelatedItems();
                setData(fetchedItem);
                setRelatedItems(fetchedRelatedItems);
            } catch (err) {
                setError("Error fetching the item");
            } finally {
                setLoading(false);
            }
        };

        fetchData();
        
        if (navMenuRef.current) {
            setNavMenuHeight(navMenuRef.current.offsetHeight);
        }

        window.scrollTo({ top: 0, behavior: "smooth" });
    }, [item]);

    return (
        <>
            {loading ? (
                <BreadcrumbsSkeleton />
            ) : (
                <>
                    <Breadcrumbs path={data.breadcrumb_path} ref={navMenuRef} />
                    <ItemInfoSport item={data} />
                    <SportItemsCarousel
                        title={"Related Products"}
                        items={relatedItems}
                    />
                    <FloatingIsland navMenuHeight={navMenuHeight} item={data} />
                </>
            )}
        </>
    );
}

export default ItemSport;
