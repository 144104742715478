import BasketListItems from "../components/BasketListItems";
import InputFieldDashed from "../../Inputs/InputFieldDashed";
import BasketSubtotal from "../components/BasketSubtotal";
import { useState } from "react";
import { placeAnOrderBodyMania, createAnOrder } from "../../../functions/fetch";
import { useTranslation } from "react-i18next";
import Cookies from "js-cookie";


function PlaceOrderSection({basketItems, defaultPromocode, openRedirectNotification, setRedirectUrl}) {

    const [promocode, setPromocode] = useState(defaultPromocode);
    const {t} = useTranslation();

    return (
        <div className="flex flex-col gap-3 sm:gap-6 rounded-[15px] sm:px-6 sm:py-6 sm:bg-gray-lidabro-select-background">
            <BasketListItems items={basketItems} />
            <InputFieldDashed
                id={"promocode"}
                name={"promocode"}
                placeholder={t("Add Promocode")}
                value={promocode}
                onChange={setPromocode}
            />
            <div className="border-b border-gray-lidabro-border-3"></div>
            <BasketSubtotal items={basketItems} />
            <button
                className="flex items-center justify-center px-[22px] py-4 rounded-lg bg-blue-lidabro-button md:bg-gray-lidabro-breadcrumb shadow-[0px_1px_2px_0px_rgba(16,24,40,0.05)] font-secondary text-base font-semibold text-white text-opacity-90"
                onClick={() => {
                    if (!Cookies.get("placeOrderClicked")) {
                        ym(
                            98895916,
                            "reachGoal",
                            "PlaceOrder",
                            {
                                session: `${Cookies.get(
                                    "sessionId"
                                )}`,
                            }
                        );
                        Cookies.set(
                            "placeOrderClicked",
                            "true",
                            {
                                expires: 365,
                            }
                        );
                    }

                    openRedirectNotification();
                    placeAnOrderBodyMania(basketItems).then(
                        (data) => {
                            if (data.cart_url) {
                                setRedirectUrl(
                                    data.cart_url
                                );
                                createAnOrder(
                                    basketItems,
                                    promocode
                                );
                            }
                        }
                    );
                }}
            >
                {t("Place an order")}
            </button>
        </div>
    )
}

export default PlaceOrderSection;