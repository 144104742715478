import { useTranslation } from "react-i18next"
import ButtonWithIconLeft from "../../Controls/ButtonWithIconLeft"
import { HeartSmallSVG } from "../../../images/SVGAssets"
import { XClose2SVG } from "../../../images/SVGAssets"
import { useContext, useState } from "react"
import { FavoriteContext } from "../../../contexts/FavoriteContext"

function OnClearBasketSection({deleteBasket, basketItems, closeSection}) {
    const {t} = useTranslation()
    const [isLoading, setIsLoading] = useState(false)
    const { addItemToFavorite } = useContext(FavoriteContext);

    const addBasketItemsToFavorite = async () => {

        setIsLoading(true)

        try {
            for(const basketItem of basketItems) {
                await addItemToFavorite(basketItem.item)
            }
            await deleteBasket()
        } catch(e) {
            console.log('couldnt add basket to favorites', e)
        } finally {
            setIsLoading(false)
        }
        
    }

    if(isLoading) {
        return <div>{t("Loading ...")}</div>
    }

    return (
        <>
            <div className="flex flex-row justify-end p-2">
                <ButtonWithIconLeft
                    icon={<XClose2SVG />}
                    callback={closeSection}
                />
            </div>
            <div className="flex flex-col h-full justify-center gap-y-4">
                <div className="flex flex-col gap-y-1 justify-around ">
                    <h2 className="flex text-center justify-center items-center font-main text-[24px] font-semibold text-gray-lidabro-breadcrumb">
                        {t("Are you sure you want to empty your basket?")}
                    </h2>
                    <h3 className="flex text-center justify-center items-center font-secondary text-[18px] font-normal">
                        {t("Once emptied, it cannot be restored.")}
                    </h3>
                </div>
                <div className="flex flex-col gap-y-2">
                    <button
                        className="flex items-center justify-center px-[22px] py-4 rounded-lg bg-blue-lidabro-button md:bg-gray-lidabro-breadcrumb shadow-[0px_1px_2px_0px_rgba(16,24,40,0.05)] font-secondary text-base font-semibold text-white text-opacity-90"
                        onClick={deleteBasket}>
                            {t("Delete")}
                    </button>

                    <button
                        className="flex flex-row justify-center px-[22px] py-4 items-center cursor-pointer p-[5px] gap-[3px] border-gray-lidabro-border-2d border rounded-lg"
                        onClick={addBasketItemsToFavorite}
                    >
                        <HeartSmallSVG
                            filled={false}
                        />
                        <p className="font-secondary font-normal  text-gray-lidabro-breadcrumb text-nowrap">
                            {t("Add To Favorites")}
                        </p>
                    </button>
                </div>
            </div>
            
        </>
    )
}

export default OnClearBasketSection;