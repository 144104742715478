import React, { createContext, useState, useEffect } from "react";
import {
    fetchBasketItemsBySession,
    addItemToBasketByArticle,
    addItemToBasketByEAN,
    updateItemBasketByArticle,
    deleteItemFromBasketByArticle,
} from "../functions/fetch";
import Cookies from "js-cookie";

export const BasketContext = createContext();

export const BasketProvider = ({ children }) => {
    const [basketItems, setBasketItems] = useState([]);
    const [totalQuantity, setTotalQuantity] = useState(0);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    const loadBasketItems = async () => {
        try {
            const items = await fetchBasketItemsBySession();
            setBasketItems(items);
        } catch (err) {
            setError(err);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        loadBasketItems();
    }, []);

    useEffect(() => {
        const newTotalQuantity = basketItems.reduce(
            (sum, item) => sum + item.quantity,
            0
        );
        setTotalQuantity(newTotalQuantity);
    }, [basketItems]);

    const refreshBasketItems = async () => {
        loadBasketItems();
    };

    const addItem = async (item, quantity) => {
        try {
            const addedItem = await addItemToBasketByEAN(item, quantity);
            setBasketItems((prevItems) => [...prevItems, addedItem]);
            if (!Cookies.get("addBasketClicked")) {
                ym(98895916, "reachGoal", "AddBasket", {
                    session: `${Cookies.get("sessionId")}`,
                });
                Cookies.set("addBasketClicked", "true", {
                    expires: 365,
                });
            }
        } catch (err) {
            console.error("Failed to add item:", err);
        }
    };

    const updateItem = async (item, quantity) => {
        try {
            const updatedItem = await updateItemBasketByArticle(item, quantity);
            setBasketItems((prevItems) =>
                prevItems.map((basketItem) =>
                    basketItem.item.article === item.article
                        ? updatedItem
                        : basketItem
                )
            );
        } catch (err) {
            console.error("Failed to update item:", err);
        }
    };

    const removeItem = async (item) => {
        try {
            await deleteItemFromBasketByArticle(item);
            setBasketItems((prevItems) =>
                prevItems.filter(
                    (basketItem) => basketItem.item.article !== item.article
                )
            );
        } catch (err) {
            console.error("Failed to remove item:", err);
        }
    };

    return (
        <BasketContext.Provider
            value={{
                basketItems,
                refreshBasketItems,
                addItem,
                updateItem,
                removeItem,
                totalQuantity,
                loading,
                error,
            }}
        >
            {children}
        </BasketContext.Provider>
    );
};
