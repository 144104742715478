import { useTranslation } from "react-i18next";
import { EyeSVG } from "../../images/SVGAssets";
import Cookies from "js-cookie";

function StreamSeeLiveQuestions({ eyezon, item }) {
    const { i18n, t } = useTranslation();
    //TODO: Streamer Time

    return (
        <>
            <div className="flex flex-col items-center p-6 gap-[25px]">
                <p className="font-secondary text-sm font-medium text-center text-blue-lidabro-text">
                    {t(
                        "Have specific questions? The seller will show the product. No need to turn on the camera"
                    )}
                </p>
                <p className="font-secondary text-sm font-medium text-center text-blue-lidabro-text">
                    {t(
                        "Get a personal live introduction to the product from the seller Sa-Mo 9:30 AM - 8:30 PM!"
                    )}
                </p>
                <button
                    data-eyezon={i18n.language === "en" ? "232" : "235"}
                    data-eyezon-title={item.name}
                    data-eyezon-target={item.ean}
                    className={`min-w-[160px] flex items-center justify-center gap-[10px] py-4 px-[22px] rounded-lg lidabro-gradient-eyezone lidabro-button-shadow text-white font-secondary text-lg font-semibold text-nowrap`}
                    onClick={() => {
                        if (!Cookies.get("streamOnClicked")) {
                            ym(98895916, "reachGoal", "StreamOn", {
                                session: `${Cookies.get("sessionId")}`,
                            });
                            Cookies.set("streamOnClicked", "true", {
                                expires: 365,
                            });
                        }
                    }}
                >
                    <EyeSVG fill="white" />
                    {t("See live")}
                </button>
            </div>
        </>
    );
}

export default StreamSeeLiveQuestions;
