import SportItemsCarousel from "../../ItemsCarousel/SportItemsCarousel";
function SportItemsCarouselBlock({relatedItems, closeBasket}) {
    return (
        <>
            <div className="flex sm:hidden">
                <SportItemsCarousel
                    title={"You may like"}
                    items={relatedItems}
                    callback={closeBasket}
                />
            </div>
            <div className="hidden sm:flex">
                <SportItemsCarousel
                    title={"Related Products"}
                    items={relatedItems}
                    callback={closeBasket}
                />
            </div>
        </>
    )
}

export default SportItemsCarouselBlock;