import { useTranslation } from "react-i18next"
import { HeartSVG } from "../../images/SVGAssets";
function FavoritesBanner({amountFavoriteItems}) {
    const {t} = useTranslation();
    return (
        <div className="font-secondary text-base font-medium text-gray-lidabro-placeholder bg-gray-lidabro-select-background py-4 px-3 rounded-lg">
            
            {
                amountFavoriteItems === 0 ? (
                    <p
                        className="flex justify-center text-center gap-1 sm:gap-2"
                    >
                        {t("Press")} <span><HeartSVG filled={false}/></span> {t("to add items to Favorites")}
                    </p>
                ) : (
                    <>
                    <span>{amountFavoriteItems} Product</span>
                    <span>{amountFavoriteItems > 1 ? "s" : ""}</span>
                    </>
                )
            }
        </div>
    )
}

export default FavoritesBanner;