import classNames from "classnames"
import { ArrowLeftSVG } from "../../../images/SVGAssets"

function ArrowButton({onClick, isLeft = true}) {
    const style = classNames(
        "h-[640px] w-[50px] flex justify-center items-center",
        {"rotate-180" : !isLeft}
    )
    return (
        <button 
            className={style}
            onClick={onClick}    
        >
            <ArrowLeftSVG
                width="25"
                height="25"
            />
        </button>
    )
}

export default ArrowButton;