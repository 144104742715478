import { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import StreamSeeLiveHelp from "../Stream/StreamSeeLiveHelp";
import {
    fetchSportRelatedItems,
    fetchBasketBySession,
    deleteBasket,
} from "../../functions/fetch";
import { BasketContext } from "../../contexts/BasketContext";
import { useContext } from "react";
import ControlsTopSection from "./Sections/ControlsTopSection";
import PlaceOrderSection from "./Sections/PlaceOrderSection";
import SportItemsCarouselBlock from "./components/SportItemsCarouselBlock";
import OnClearBasketSection from "./Sections/OnClearBasketSection";

function BasketSport(params) {
    //TODO: SkeletonUI
    const { basketItems, refreshBasketItems, loading, error } =
        useContext(BasketContext);
    const emptyBasket = { promocode: "" };
    const [basket, setBasket] = useState(emptyBasket);
    const [relatedItems, setRelatedItems] = useState([]);
    const [isClearBasketMode, setIsClearBasketMode] = useState(false);
    const { t } = useTranslation();

    const fetchRelatedItems = async () => {
        const fetchedBasket = await fetchBasketBySession();
        const fetchedRelatedItems = await fetchSportRelatedItems();
        setBasket(fetchedBasket);
        setRelatedItems(fetchedRelatedItems);
    };

    useEffect(() => {
        fetchRelatedItems();
    }, []);

    const isBasketEmpty = basketItems.length === 0
    
    if(loading) {
        return <div>{t("Loading")}</div>
    }
    
    if(isClearBasketMode && !isBasketEmpty) {
        return (
            <div className="flex flex-col w-full md:max-w-[650px]">
                <div className="max-w-full h-screen md:max-h-96 flex flex-col gap-3 px-3 sm:px-[45px] pb-3 sm:pb-12 bg-white">
                    <OnClearBasketSection
                        deleteBasket = {async () => {
                            await deleteBasket();
                            await refreshBasketItems();
                            setBasket(emptyBasket);
                            setIsClearBasketMode(false);
                        }}
                        basketItems = {basketItems}
                        closeSection = {() => setIsClearBasketMode(false)}
                    />
                </div>
            </div>
        )
    }

    return (
        <div className="flex flex-col w-full md:max-w-[650px]">
            <ControlsTopSection
                openClearBasketSection = {() => setIsClearBasketMode(true)}
                isBasketEmpty = {isBasketEmpty}
                closeBasket = {params.closeBasket}
            />

            <div className="max-w-full flex flex-col gap-3 px-3 sm:px-[45px] pb-3 sm:pb-12 bg-white">
                {
                !isBasketEmpty && (
                <PlaceOrderSection
                    basketItems = {basketItems}
                    defaultPromocode = {basket.promocode}
                    openRedirectNotification = {params.openRedirectNotification}
                    setRedirectUrl = {params.setRedirectUrl}
                />
                )}
                <div className="flex flex-col sm:flex-col-reverse gap-6">
                    {
                        !isBasketEmpty && <SportItemsCarouselBlock relatedItems = {relatedItems} closeBasket = {params.closeBasket}/>
                    }
                    <StreamSeeLiveHelp
                        eyezon={{
                            id: "232",
                            target: "",
                            title: "Customer Basket",
                        }}
                        textChildren={
                            isBasketEmpty && (
                            <p
                                className="font-montserrat font-semibold text-[18px] leading-[28px] lg:font-main lg:font-normal lg:text-[30px] lg:leading-[38px]"
                            >
                                {t("Looks like you haven’t selected anything yet ...")}
                            </p>)
                        }
                    />
                    {
                        !isBasketEmpty && <SportItemsCarouselBlock relatedItems = {relatedItems} closeBasket = {params.closeBasket}/>
                    }
                </div>
            </div>
        </div>
    );
}

export default BasketSport;
